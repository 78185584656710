.sv_main.sv_default_css {
  background-color: var(--body-container-background-color, $body-container-background-color);
}

.sv_main.sv_default_css hr {
  border-color: var(--border-color, $border-color);
}

.sv_main.sv_default_css input[type="button"],
.sv_main.sv_default_css button {
  color: var(--body-background-color, $body-background-color);
  background-color: var(--main-color, $main-color);
}
.sv_main.sv_default_css input[type="button"]:hover,
.sv_main.sv_default_css button:hover {
  background-color: var(--main-hover-color, $main-hover-color);
}

.sv_main.sv_default_css .sv_header {
  color: var(--header-color, $header-color);
}
.sv_main.sv_default_css .sv_custom_header {
  background-color: var(--header-background-color, $header-background-color);
}
.sv_main.sv_default_css .sv_container {
  color: var(--text-color, $text-color);
}
.sv_main.sv_default_css .sv_body {
  background-color: var(--body-background-color, $body-background-color);
  border-color: var(--main-color, $main-color);
}
.sv_main.sv_default_css .sv_progress {
  background-color: var(--border-color, $border-color);
}
.sv_main.sv_default_css .sv_progress_bar {
  background-color: var(--main-color, $main-color);
}

.sv_main.sv_default_css .sv_progress-buttons__list li:before {
  border-color: var(--progress-buttons-color, $progress-buttons-color);
  background-color: var(--progress-buttons-color, $progress-buttons-color);
}
.sv_main.sv_default_css .sv_progress-buttons__list li:after {
  background-color: var(--progress-buttons-line-color, $progress-buttons-line-color);
}
.sv_main.sv_default_css .sv_progress-buttons__list .sv_progress-buttons__page-title {
  color: var(--text-color, $text-color);
}
.sv_main.sv_default_css .sv_progress-buttons__list .sv_progress-buttons__page-description {
  color: var(--text-color, $text-color);
}
.sv_main.sv_default_css .sv_progress-buttons__list li.sv_progress-buttons__list-element--passed:before {
  border-color: var(--main-color, $main-color);
  background-color: var(--main-color, $main-color);
}
.sv_main.sv_default_css .sv_progress-buttons__list li.sv_progress-buttons__list-element--passed + li:after {
  background-color: var(--progress-buttons-color, $progress-buttons-color);
}
.sv_main.sv_default_css .sv_progress-buttons__list li.sv_progress-buttons__list-element--current:before {
  border-color: var(--main-color, $main-color);
  background-color: white;
}
.sv_main.sv_default_css
  .sv_progress-buttons__list
  li.sv_progress-buttons__list-element--passed.sv_progress-buttons__list-element--current:before {
  border-color: var(--main-color, $main-color);
  background-color: white;
}

.sv_main.sv_default_css .sv_p_root > .sv_row {
  border-color: var(--border-color, $border-color);
}
.sv_main.sv_default_css .sv_p_root > .sv_row:nth-child(odd) {
  background-color: var(--body-background-color, $body-background-color);
}
.sv_main.sv_default_css .sv_p_root > .sv_row:nth-child(even) {
  background-color: var(--body-container-background-color, $body-container-background-color);
}

.sv_main.sv_default_css .sv_q_other input {
  color: var(--text-color, $text-color);
  -webkit-text-fill-color: var(--text-color, $text-color);
  border-color: var(--border-color, $border-color);
  background-color: var(--inputs-background-color, $inputs-background-color);
}
.sv_main.sv_default_css .sv_q_text_root {
  color: var(--text-color, $text-color);
  -webkit-text-fill-color: var(--text-color, $text-color);
  border-color: var(--border-color, $border-color);
  background-color: var(--inputs-background-color, $inputs-background-color);
}
.sv_main.sv_default_css .sv_q_dropdown_control {
  color: var(--text-input-color, $text-input-color);
  border-color: var(--border-color, $border-color);
  background-color: var(--inputs-background-color, $inputs-background-color);
}
.sv_main.sv_default_css .sv_q_dropdown_control__input-field-component {
  height: auto;
}
.sv_main.sv_default_css input[type="text"] {
  color: var(--text-color, $text-color);
  -webkit-text-fill-color: var(--text-color, $text-color);
  border-color: var(--border-color, $border-color);
  background-color: var(--inputs-background-color, $inputs-background-color);
}
.sv_main.sv_default_css select {
  color: var(--text-color, $text-color);
  border-color: var(--border-color, $border-color);
  background-color: var(--inputs-background-color, $inputs-background-color);
}
.sv_main.sv_default_css textarea {
  color: var(--text-input-color, $text-input-color);
  -webkit-text-fill-color: var(--text-input-color, $text-input-color);
  border-color: var(--border-color, $border-color);
  background-color: var(--inputs-background-color, $inputs-background-color);
}
.sv_main.sv_default_css
  input:not([type="button"]):not([type="reset"]):not([type="submit"]):not([type="image"]):not([type="checkbox"]):not([type="radio"]) {
  border: 1px solid var(--border-color, $border-color);
  background-color: var(--inputs-background-color, $inputs-background-color);
  color: var(--text-input-color, $text-input-color);
  -webkit-text-fill-color: var(--text-input-color, $text-input-color);
}
.sv_main.sv_default_css
  input:not([type="button"]):not([type="reset"]):not([type="submit"]):not([type="image"]):not([type="checkbox"]):not([type="radio"]):focus {
  border: 1px solid var(--main-color, $main-color);
}
.sv_main.sv_default_css .sv_container .sv_body .sv_p_root .sv_q .sv_select_wrapper .sv_q_dropdown_control {
  background-color: var(--inputs-background-color, $inputs-background-color);
}
.sv_main.sv_default_css .sv_container .sv_body .sv_p_root .sv_q .sv_select_wrapper .sv_q_dropdown_control:focus-within {
  border: 1px solid var(--main-color, $main-color);
}
.sv_main.sv_default_css .sv_q_other input:focus {
  border-color: var(--main-color, $main-color);
}
.sv_main.sv_default_css .sv_q_text_root:focus {
  border-color: var(--main-color, $main-color);
}
.sv_main.sv_default_css .sv_q_dropdown_control:focus {
  border-color: var(--main-color, $main-color);
}
.sv_main.sv_default_css
  .sv_container
  .sv_body
  .sv_p_root
  .sv_q
  .sv_q_dropdown_control
  .sv_q_dropdown__value
  .sv_q_dropdown__filter-string-input[type="text"] {
  border: none;
  outline: none;
  padding: 0px;
  width: auto;
  display: flex;
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  position: absolute;
  inset-inline-start: 0;
  top: 0;
  height: 100%;
  background-color: transparent;
}
.sv_main.sv_default_css
  .sv_container
  .sv_body
  .sv_p_root
  .sv_q
  .sv_q_dropdown_control
  .sv_q_dropdown__value.sv_q_tagbox__value
  .sv_q_dropdown__filter-string-input.sv_q_tagbox__filter-string-input {
  position: initial;
}
.sv_main.sv_default_css input[type="text"]:focus {
  border-color: var(--main-color, $main-color);
}
.sv_main.sv_default_css .sv_container .sv_body .sv_p_root .sv_q input[type="radio"]:focus,
.sv_main.sv_default_css .sv_container .sv_body .sv_p_root .sv_q input[type="checkbox"]:focus {
  outline: 1px dotted var(--main-color, $main-color);
}
.sv_main.sv_default_css select:focus {
  border-color: var(--main-color, $main-color);
}
.sv_main.sv_default_css textarea:focus {
  border-color: var(--main-color, $main-color);
}
.sv_main.sv_default_css .sv_q_input.sv_q_input.sv_q_input.sv_q_tagbox.sv_q_tagbox.sv_q_tagbox:not(.sv_q_tagbox--empty) {
  height: auto;
}
.sv_main.sv_default_css .sv_select_wrapper.sv_q_tagbox_wrapper::before {
  height: 100%;
  padding: 0 1em;
}

.sv_main.sv_default_css .sv_select_wrapper {
  background-color: var(--body-background-color, $body-background-color);
}
.sv_main.sv_default_css .sv_select_wrapper::before {
  background-color: var(--main-color, $main-color);
}

.sv_main.sv_default_css .sv_q_rating_item.active .sv_q_rating_item_text {
  background-color: var(--main-hover-color, $main-hover-color);
  border-color: var(--main-hover-color, $main-hover-color);
  color: var(--body-background-color, $body-background-color);
}
.sv_main.sv_default_css .sv_q_rating_item .sv_q_rating_item_text {
  border-color: var(--border-color, $border-color);
}
.sv_main.sv_default_css .sv_q_rating_item .sv_q_rating_item_text:hover {
  border-color: var(--main-hover-color, $main-hover-color);
}

.sv_main.sv_default_css table.sv_q_matrix tr {
  border-color: var(--border-color, $border-color);
}
.sv_main.sv_default_css table.sv_q_matrix_dropdown tr {
  border-color: var(--border-color, $border-color);
}
.sv_main.sv_default_css table.sv_q_matrix_dynamic tr {
  border-color: var(--border-color, $border-color);
}

.sv_main.sv_default_css .sv_q_m_cell_selected {
  color: var(--body-background-color, $body-background-color);
  background-color: var(--main-hover-color, $main-hover-color);
}

.sv_main .sv_q_file_remove:hover {
  color: var(--main-color, $main-color);
}
.sv_main .sv_q_file_choose_button {
  color: var(--body-background-color, $body-background-color);
  background-color: var(--main-color, $main-color);
}
.sv_main .sv_q_file_choose_button:hover {
  background-color: var(--main-hover-color, $main-hover-color);
}

.sv_main .sv_q_imgsel.checked label > div {
  background-color: var(--main-color, $main-color);
}

.sv_main.sv_default_css .sv_p_description {
  padding-left: 1.29em;
}
//progress bar
.sv_main .sv-progress {
  background-color: var(--header-background-color, $header-background-color);
}
.sv_main .sv-progress__bar {
  background-color: var(--main-color, $main-color);
}

//paneldynamic
.sv_main .sv-paneldynamic__prev-btn.sv-paneldynamic__prev-btn--disabled,
.sv_main .sv-paneldynamic__next-btn.sv-paneldynamic__next-btn--disabled {
  fill: var(--disable-color, $disable-color);
}
.sv_main .sv-paneldynamic__progress-text {
  color: var(--progress-text-color, $progress-text-color);
}
.sv_main .sv-paneldynamic__prev-btn,
.sv_main .sv-paneldynamic__next-btn {
  fill: var(--text-color, $text-color);
}

//boolean
.sv_main .sv-boolean__switch {
  background-color: var(--main-color, $main-color);
  outline-color: var(--main-color, $main-color);
}
.sv_main .sv-boolean__slider {
  background-color: var(--slider-color, $slider-color);
}
.sv_main .sv-boolean__label--disabled {
  color: var(--disabled-label-color, $disabled-label-color);
}
.sv_main .sv-boolean--disabled .sv-boolean__switch {
  background-color: var(--disabled-switch-color, $disabled-switch-color);
}
.sv_main .sv-boolean--disabled .sv-boolean__slider {
  background-color: var(--disabled-slider-color, $disabled-slider-color);
}
//eo boolean

//signature pad
.sv_main .sjs_sp_container {
  border: 1px dashed var(--disable-color, $disable-color);
}
.sv_main .sjs_sp_placeholder {
  color: var(--foreground-light, $foreground-light);
}

.sv_main .sv_matrix_detail_row {
  background-color: #ededed;
  border-top: 1px solid var(--header-background-color, $header-background-color);
  border-bottom: 1px solid var(--header-background-color, $header-background-color);
}

//action-bar
.sv_main .sv-action-bar-item {
  color: var(--text-color, $text-color);
}
.sv_main .sv-action-bar-item__icon use {
  fill: var(--foreground-light, $foreground-light);
}
.sv_main .sv-action-bar-item:hover {
  background-color: var(--background-dim, $background-dim);
}
//eo action-bar

//button-group
.sv_main .sv-button-group__item--hover:hover {
  background-color: var(--background-dim, $background-dim);
}
.sv_main .sv-button-group__item-icon use {
  fill: var(--foreground-light, $foreground-light);
}
.sv_main .sv-button-group__item--selected {
  color: var(--main-color, $main-color);
}
.sv_main .sv-button-group__item--selected .sv-button-group__item-icon use {
  fill: var(--main-color, $main-color);
}
.sv_main .sv-button-group__item--disabled {
  color: var(--foreground-disabled, $foreground-disabled);
}
.sv_main .sv-button-group__item--disabled .sv-button-group__item-icon use {
  fill: var(--foreground-disabled, $foreground-disabled);
}
.sv_main .sv-button-group__item {
  background: var(--body-background-color, $body-background-color);
  border-color: var(--border-color, $border-color);
}

//eo button-group

.sv_main .sv_qstn textarea {
  max-width: 100%;
}

//list
body .sv-list__input {
  color: var(--text-input-color, $text-input-color);
  border-color: var(--border-color, $border-color);
  background-color: var(--inputs-background-color, $inputs-background-color);
}
body .sv-list__input::placeholder {
  color: var(--foreground-light, $foreground-light);
}
body .sv-list__input:disabled {
  color: var(--foreground-disabled, $foreground-disabled);
}
body .sv-list__input:disabled::placeholder {
  color: var(--foreground-disabled, $foreground-disabled);
}
body .sv-list__item--selected {
  background: rgba(25, 179, 148, 0.1);
  color: var(--foreground-disabled, $foreground-disabled);
}
body .sv-list__item--selected.sv-list__item--focused {
  background-color: var(--main-color, $main-color);
  color: var(--inputs-background-color, $inputs-background-color);
}
body .sv-list__item--selected:focus {
  background-color: var(--main-color, $main-color);
  color: var(--inputs-background-color, $inputs-background-color);
}
body .sv-list__item--selected:hover {
  background-color: var(--main-color, $main-color);
  color: var(--inputs-background-color, $inputs-background-color);
}
//eo list
.sv-skeleton-element {
  background-color: var(--background-dim, $background-dim);
}

//ranking
.sv_main .sv-ranking-item:focus .sv-ranking-item__index {
  border: 2px solid var(--main-color, $main-color);
}
.sv_main .sv-ranking-item__icon {
  fill: var(--main-color, $main-color);
}
body .sv-ranking-shortcut .sv-ranking-item__icon {
  fill: var(--main-color, $main-color);
  //eo ranking
}
